<template>
  <div style="background-color: #f5f5f5; min-height: 100vh">
    <overlay :value="loading"></overlay>

    <v-app-bar color="primary" dark elevation="0" app>
      <v-app-bar-nav-icon class="d-none d-md-block" @click.stop="mini = !mini"></v-app-bar-nav-icon>
      <v-img src="@/assets/Isotipo_Negativo.png" max-width="40px" class="" contain></v-img>
      <v-toolbar-title class="d-none d-sm-block ml-4">Administrador <b>Pawer</b></v-toolbar-title>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="logout" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span class="text-center">Cerrar sesión</span>
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      app
      touchless
      permanent
      style="min-height: 90vh"
      fill-height
    >
      <v-list-item class="px-2 my-md-1" style="border: none">
        <v-list-item-avatar>
          <v-img
            :src="
              user != null && user.imageUrl != null
                ? user.imageUrl
                : 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'
            "
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-title>
          <p class="ma-0 font-weight-bold">{{ user ? user.name : '' }}</p>
          <p class="ma-0">{{ user ? user.email : '' }}</p>
        </v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>
      <template>
        <v-list nav dense>
          <v-list-group
            no-action
            v-for="(item, i) in items"
            :key="i"
            :prepend-icon="item.icon"
            :class="{ 'no-arrow': !item.subLinks }"
          >
            <template v-slot:activator>
              <v-list-item :to="item.link">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item v-for="(sub, s) in item.subLinks" :key="s + 10000" :to="sub.link">
              <v-list-item-icon>
                <v-icon>{{ sub.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ sub.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Overlay from '../components/Overlay.vue';
import AuthService from '../services/auth.service';

export default {
  components: { Overlay },
  data() {
    return {
      loading: true,
      user: null,
      drawer: false,
      overwriteBreakpoint: true,
      items: [
        {
          title: 'Home',
          icon: 'mdi-home',
          link: '/'
        },
        {
          title: 'Usuarios',
          icon: 'mdi-account-group',
          link: '/users'
        },
        {
          title: 'MRR',
          icon: 'mdi-cash-multiple',
          subLinks: [
            {
              link: '/mrr/users',
              title: 'Usuarios',
              icon: 'mdi-account-cash'
            },
            {
              link: '/mrr/values',
              title: 'Detalle',
              icon: 'mdi-cash-check'
            }
          ]
        },
        {
          title: 'Empresas',
          icon: 'mdi-briefcase',
          link: '/companies'
        },
        {
          title: 'Membresías',
          icon: 'mdi-badge-account-horizontal',
          link: '/memberships'
        },
        {
          title: 'Mascotas',
          icon: 'mdi-paw',
          link: '/pets'
        },
        /*{
          title: 'Proveedores',
          icon: 'mdi-shield-check',
          link: '/homologations'
        },*/
        {
          title: 'Planes',
          icon: 'mdi-clipboard-list',
          link: '/plans'
        },
        {
          title: 'Cupones',
          icon: 'mdi-ticket-percent',
          link: '/coupons'
        },
        /*{
          title: 'Canjes y referidos',
          icon: 'mdi-share-variant',
          link: '/redemptions'
        },*/
        {
          title: 'Pagos recibidos',
          icon: 'mdi-cash',
          link: '/payments'
        },
        {
          title: 'Donaciones',
          icon: 'mdi-hand-heart',
          link: '/donations'
        },
        {
          title: 'Reembolsos',
          // icon: 'mdi-cash-refund',
          icon: 'mdi-bank-transfer-in',
          link: '/refunds'
        },
        {
          title: 'Registros',
          icon: 'mdi-toolbox-outline',
          subLinks: [
            {
              link: '/registers/servicesrecords',
              title: 'Servicios',
              icon: 'mdi-medical-bag'
            },
            {
              link: '/registers/register-refunds',
              title: 'Reembolsos',
              icon: 'mdi-cash-refund'
            }
          ]
        },
        {
          title: 'Reembolsos 2.0',
          // icon: 'mdi-cash-refund',
          icon: 'mdi-bank-transfer-in',
          link: '/refunds/new'
        }
      ]
    };
  },
  computed: {
    mini: {
      get() {
        return this.$vuetify.breakpoint.mdAndDown || this.overwriteBreakpoint;
      },
      set(value) {
        this.overwriteBreakpoint = value;
      }
    }
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    async getUserData() {
      const res = await AuthService.getUserData();
      if (res.status === 'ok') {
        this.user = res.data;
      } else {
        this.$router.push({ path: '/login' });
      }
      this.loading = false;
    },
    async logout() {
      this.loading = true;
      const res = await AuthService.logout();
      if (res.status === 'ok') {
        this.$router.push({ path: '/login' });
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
<style lang="scss">
.v-navigation-drawer__border {
  width: 0 !important;
}
.no-arrow .v-list-group__header__append-icon {
  display: none !important;
}
</style>
